import axios from 'axios'

class AdminApi {
  async getCenter(page, limit, search) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post('/admin/center/get', {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getOneCenter(center_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios

      .get(`/admin/center/getOne/center_id/${center_id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addCenter(formData) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/admin/center/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editCenter(formData) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/admin/center/edit`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editNoteCenter(_id, center_admin_note) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put('/admin/center/editNote', {
        center_admin_note,
        _id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeCenter(center_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/admin/center/center_id/${center_id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getManager(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/admin/manager/get/center_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addManager({
    account_name,
    account_mobile,
    account_type,
    account_address,
    account_email,
    account_password,
    account_password_show,
    account_center,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/admin/manager/add`, {
        account_name,
        account_mobile,
        account_type,
        account_address,
        account_email,
        account_password,
        account_password_show,
        account_center,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editManager({
    account_name,
    account_mobile,
    account_type,
    account_address,
    account_email,
    account_password,
    account_password_show,
    account_center,
    account_id,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/admin/manager/edit`, {
        account_name,
        account_mobile,
        account_type,
        account_address,
        account_email,
        account_password,
        account_password_show,
        account_center,
        account_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeManager(account_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/admin/manager/remove/account_id/${account_id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }
}

export default new AdminApi()
